import React from "react";

const IconAddUser = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9998 18.5028V21.2895C12.7931 20.8628 11.5015 20.732 10.2337 20.9079C8.96588 21.0838 7.75877 21.5613 6.71375 22.3004C5.66872 23.0395 4.8163 24.0186 4.22805 25.1554C3.63981 26.2922 3.33293 27.5535 3.33317 28.8335L0.666504 28.8322C0.66609 27.204 1.0384 25.5974 1.7549 24.1354C2.47141 22.6733 3.51309 21.3947 4.80013 20.3975C6.08717 19.4003 7.58541 18.711 9.18004 18.3824C10.7747 18.0537 12.4234 18.0958 13.9998 18.5028ZM11.3332 16.8335C6.91317 16.8335 3.33317 13.2535 3.33317 8.8335C3.33317 4.4135 6.91317 0.833496 11.3332 0.833496C15.7532 0.833496 19.3332 4.4135 19.3332 8.8335C19.3332 13.2535 15.7532 16.8335 11.3332 16.8335ZM11.3332 14.1668C14.2798 14.1668 16.6665 11.7802 16.6665 8.8335C16.6665 5.88683 14.2798 3.50016 11.3332 3.50016C8.3865 3.50016 5.99984 5.88683 5.99984 8.8335C5.99984 11.7802 8.3865 14.1668 11.3332 14.1668ZM19.3332 22.1668V18.1668H21.9998V22.1668H25.9998V24.8335H21.9998V28.8335H19.3332V24.8335H15.3332V22.1668H19.3332Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconAddUser;
