import React from "react";
import Skeleton from "react-loading-skeleton";
import BlockHeader from "../../../Molecule/Block/BlockHeader";
import BlockComponent from "../../../Molecule/Block";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";

const WocamDetailSkeleton = () => {
  return (
    <div className="main-body-wrapper">
      <PageTitleSkeleton />
      <div className={"row mb-5"}>
        <div className={"col-xl-4 col-lg-4 col-md-4"}>
          <BlockComponent className={`no-panel-fh mb-0`}>
            <div className={"element-section"}>
              <BlockHeader>
                <h5 className={"element-title-h5"}>
                  <Skeleton height={20} width={100} />
                </h5>
              </BlockHeader>
              <tbody className={"data-table-list"}>
                <Skeleton
                  count={4}
                  width={350}
                  height={10}
                  className={"mb-3"}
                />
              </tbody>
            </div>
          </BlockComponent>
        </div>
        <div className={"col-xl-4 col-lg-4 col-md-4"}>
          <BlockComponent className={`no-panel-fh mb-0`}>
            <div className={"element-section"}>
              <BlockHeader>
                <h5 className={"element-title-h5"}>
                  <Skeleton height={20} width={100} />
                </h5>
              </BlockHeader>
              <tbody className={"data-table-list"}>
                <Skeleton
                  count={4}
                  width={350}
                  height={10}
                  className={"mb-3"}
                />
              </tbody>
            </div>
          </BlockComponent>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4">
          <Skeleton
            className={"video-skeleton"}
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
      <div className="mb-3">
        <Skeleton height={15} width={200} />
      </div>
      <div className={"row mb-5"}>
        <div className={"col-xl-4 col-lg-4 col-md-4"}>
          <BlockComponent className={`no-panel-fh mb-0`}>
            <div className={"element-section"}>
              <BlockHeader>
                <h5 className={"element-title-h5"}>
                  <Skeleton height={20} width={100} />
                </h5>
              </BlockHeader>
              <tbody className={"data-table-list"}>
                <Skeleton
                  count={3}
                  width={350}
                  height={10}
                  className={"mb-3"}
                />
              </tbody>
            </div>
          </BlockComponent>
        </div>
        <div className={"col-xl-4 col-lg-4 col-md-4"}>
          <BlockComponent className={`no-panel-fh mb-0`}>
            <div className={"element-section"}>
              <BlockHeader>
                <h5 className={"element-title-h5"}>
                  <Skeleton height={20} width={100} />
                </h5>
              </BlockHeader>
              <tbody className={"data-table-list"}>
                <Skeleton
                  count={3}
                  width={350}
                  height={10}
                  className={"mb-3"}
                />
              </tbody>
            </div>
          </BlockComponent>
        </div>
        <div className={"col-xl-4 col-lg-4 col-md-4"}>
          <BlockComponent className={`no-panel-fh mb-0`}>
            <div className={"element-section"}>
              <BlockHeader>
                <h5 className={"element-title-h5"}>
                  <Skeleton height={20} width={100} />
                </h5>
              </BlockHeader>
              <tbody className={"data-table-list"}>
                <Skeleton
                  count={3}
                  width={350}
                  height={10}
                  className={"mb-3"}
                />
              </tbody>
            </div>
          </BlockComponent>
        </div>
      </div>
    </div>
  );
};

export default WocamDetailSkeleton;
