import React from "react";

const IconCameraZeroState = ({ height = 60, width = 60, fill = "#d2d2d2" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path
        d="M75 65.9422V30C75 26.7 72.3 24 69 24H59.49L54 18H36L30.51 24H21.6717L29.3006 30H33.15L34.92 28.05L38.64 24H51.36L55.08 28.05L56.85 30H69V61.2232L75 65.9422Z"
        fill={fill}
      />
      <path
        d="M58.6878 53.1127C59.5309 51.238 60 49.1592 60 46.9714C60 38.6914 53.28 31.9714 45 31.9714C41.5379 31.9714 38.3485 33.1463 35.8091 35.1189L40.2586 38.6184C41.6565 37.8245 43.2744 37.3714 45 37.3714C50.31 37.3714 54.6 41.6614 54.6 46.9714C54.6 47.8869 54.4725 48.772 54.2342 49.6101L58.6878 53.1127Z"
        fill={fill}
      />
      <path
        d="M50.9148 54.5385L55.2337 57.9353C52.553 60.4387 48.9544 61.9714 45 61.9714C36.72 61.9714 30 55.2514 30 46.9714C30 44.296 30.7016 41.7834 31.9308 39.6078L36.2518 43.0062C35.7045 44.2143 35.4 45.5567 35.4 46.9714C35.4 52.2814 39.69 56.5714 45 56.5714C47.2331 56.5714 49.2857 55.8127 50.9148 54.5385Z"
        fill={fill}
      />
      <path
        d="M21 31.0109V66H65.4877L72.0501 71.1613C71.155 71.6937 70.1115 72 69 72H21C17.7 72 15 69.3 15 66V30C15 28.8889 15.3061 27.8459 15.838 26.951L21 31.0109Z"
        fill={fill}
      />
      <line
        x1="6.83171"
        y1="21.671"
        x2="78.1533"
        y2="77.7647"
        stroke={fill}
        stroke-width="5.92595"
      />
    </svg>
  );
};

export default IconCameraZeroState;
