import React from "react";

const IconDrivethru = ({ height = "18", width = "24", color = "black" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4234 9.39912C16.0757 9.39912 15.7938 9.73219 15.7938 10.1431C15.7938 10.5539 16.0757 10.887 16.4234 10.887H18.9415C19.2892 10.887 19.571 10.5539 19.571 10.1431C19.571 9.73219 19.2892 9.39912 18.9415 9.39912H16.4234Z"
        fill={color}
      />
      <path
        d="M4.33901 10.1433C4.33901 9.73241 4.62086 9.39934 4.96854 9.39934H7.48666C7.83434 9.39934 8.11619 9.73241 8.11619 10.1433C8.11619 10.5541 7.83434 10.8872 7.48666 10.8872H4.96854C4.62086 10.8872 4.33901 10.5541 4.33901 10.1433Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.17755 1.21044C5.50256 0.606895 6.11626 0.233032 6.78198 0.233032H17.0925C17.7582 0.233032 18.3719 0.606895 18.6969 1.21043L20.7669 5.05443C20.914 4.66954 21.0771 4.39741 21.5 4.39741H22.9979C23.5513 4.39741 24 4.86349 24 5.43843V5.95893C24 6.53387 23.5513 6.99995 22.9979 6.99995H21.8146L21.8608 7.08573V12.3984C21.8608 13.1173 21.3152 13.7037 20.6308 13.7354V14.7552C20.6308 15.1964 20.352 15.5977 19.9325 15.6667C19.4862 15.7401 19.081 15.734 18.6198 15.6589C18.1872 15.5884 17.8909 15.1809 17.8909 14.726V14.0414L17.7512 13.7368H6.18302L6.04338 14.0414V14.726C6.04338 15.1809 5.74704 15.5884 5.31441 15.6589C4.8533 15.734 4.44803 15.7401 4.00174 15.6667C3.58222 15.5977 3.30346 15.1964 3.30346 14.7552V13.7368H3.30202C2.59049 13.7368 2.01369 13.1376 2.01369 12.3984V7.08573L2.05988 6.99995H1.00211C0.448658 6.99995 -1.90735e-06 6.53387 -1.90735e-06 5.95893V5.43843C-1.90735e-06 4.86349 0.448659 4.39741 1.00211 4.39741H2.5C2.87516 4.39741 3.00354 4.61157 3.17529 4.92863L5.17755 1.21044ZM6.78198 1.76687C6.65093 1.76687 6.53012 1.84046 6.46615 1.95927L4.01085 6.51875H19.8636L17.4083 1.95927C17.3443 1.84046 17.2235 1.76687 17.0925 1.76687H6.78198ZM20.3842 8.05258H3.4902V12.203H20.3842V8.05258Z"
        fill={color}
      />
    </svg>
  );
};

export default IconDrivethru;
