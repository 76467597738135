import React from "react";

const IconPlansLimit = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.29581 4H18.2958V8.00006C18.8724 8.00268 19.4297 8.08828 19.9567 8.2457C20.075 8.28102 20.1879 8.32009 20.2958 8.36277V4C20.2958 2.89543 19.4004 2 18.2958 2H2.29581C1.19124 2 0.295807 2.89543 0.295807 4V20C0.295807 21.1046 1.19124 22 2.29581 22H18.2958C19.4004 22 20.2958 21.1046 20.2958 20L2.29581 20V4Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.539 7C13.747 7 14.7549 7.85677 14.9881 8.99579C13.7458 9.8262 12.8261 11.1147 12.4613 12.625H6.28901C4.90829 12.625 3.789 11.5057 3.789 10.125V9.5C3.789 8.11929 4.90829 7 6.289 7H12.539ZM6.289 9H12.539C12.8152 9 13.039 9.22386 13.039 9.5V10.125C13.039 10.4011 12.8152 10.625 12.539 10.625H6.28901C6.01286 10.625 5.789 10.4011 5.789 10.125V9.5C5.789 9.22386 6.01286 9 6.289 9Z"
        fill="#545454"
      />
      <path
        d="M6.289 14.5C5.59864 14.5 5.039 15.0596 5.039 15.75C5.039 16.4404 5.59864 17 6.289 17C6.97936 17 7.539 16.4404 7.539 15.75C7.539 15.0596 6.97936 14.5 6.289 14.5Z"
        fill="#545454"
      />
      <path
        d="M10.039 14.5C9.34865 14.5 8.789 15.0596 8.789 15.75C8.789 16.4404 9.34865 17 10.039 17C10.7294 17 11.289 16.4404 11.289 15.75C11.289 15.0596 10.7294 14.5 10.039 14.5Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9585 9.12807C18.1253 9.1115 18.2945 9.10303 18.4655 9.10303C21.2675 9.10303 23.539 11.3745 23.539 14.1765C23.539 14.3475 23.5305 14.5167 23.5139 14.6836L23.4916 14.9088H17.7332V9.15044L17.9585 9.12807ZM19.1979 10.642V13.4441H22C21.7104 12.0388 20.6032 10.9316 19.1979 10.642Z"
        fill="#545454"
      />
      <path
        d="M17.2685 9.23328V10.7667L17.1123 10.83C15.7893 11.3655 14.8568 12.6624 14.8568 14.1765C14.8568 16.1696 16.4725 17.7853 18.4655 17.7853C19.9797 17.7853 21.2765 16.8527 21.8121 15.5297L21.8753 15.3735H23.4087L23.3067 15.6985C22.6603 17.7566 20.7376 19.25 18.4655 19.25C15.6635 19.25 13.3921 16.9785 13.3921 14.1765C13.3921 11.9044 14.8854 9.98176 16.9436 9.33533L17.2685 9.23328Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconPlansLimit;
