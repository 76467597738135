import React, { useEffect, useState } from "react";
import styles from "./brink.module.scss";
import Select from "react-select";

import Tag from "../../../../Common/Components/Molecule/Tag";
import BrinkLocationModal from "./BrinkLocationModal";
import BlocksComponent from "../../../../Common/Components/Molecule/Block";
import { showAlert } from "../../../../Services/showAlert";
import {
  IconSyncRound,
  IconTrash,
} from "../../../../Common/Components/IconsComponent/Index";
import { colourStyles } from "../../../../Services/colourStyles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
let defaultErrorLabels = {
  token: "",
};
const BrinkLocation = (props) => {
  const [error, setError] = useState(defaultErrorLabels);
  const [Location, setLocation] = useState([]);
  const [selectedWobot, setSelectedWobot] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);
  const [smallDiv, setSmallDiv] = useState([1]);
  const [data, setData] = useState([]);
  const [addLocationToken, setAddLocationToken] = useState("");
  const [addItem, setAddItem] = useState([]);
  useEffect(() => {
    if (props?.data?.brinkLocation) {
      let locationList = [];
      let locationCount = [];

      props?.data?.brinkLocation.map((el, index) => {
        locationList.push({
          brinkId: el?.location?._id,
          locationToken: el?.locationToken,
          name: el.name,
          location: {
            value: el?.location?._id,
            label: el?.location?.area,
          },
          data: el?.location,
        });
        if (el?.location !== null) {
          locationCount = [...locationCount, index];
        }
      });
      setLocation(locationList);
      setSelectedWobot(locationCount);
    }
  }, [props?.data?.brinkLocation]);
  console.log("seleted location", selectedWobot);
  const OpenSelectedModal = () => {
    setShow(true);
  };
  const callApiClick = (index) => {
    setSelectedModal(index);
  };
  const handleSmallDivCount = (e) => {
    // e.preventDefault();
    if (smallDiv.length === Location.length) {
      setSmallDiv((arr) => [...arr, smallDiv + 1]);
    } else {
      showAlert("Please enter all details", "warning");
    }
  };
  return (
    <div className={styles.jolt_location_page}>
      <BlocksComponent>
        <div className={styles.check_jolt_connection}>
          <div className={styles.check_delete}>
            <h4>Connected Brink Locations</h4>
            <button
              className={`btn btn-secondary ml-2 ${styles.svg_button}`}
              onClick={props.data.successCallBack}
            >
              <IconSyncRound /> Sync data with Brink
            </button>
          </div>
          <p className={"mb-3"}>
            Map Brink locations and their POS systems and with Wobot.ai
            platform.
          </p>
          <div className="divider" />

          {Location &&
            Location?.map((el, index) => {
              return (
                <div
                  className={"mt-3 mb-2" + styles.jolt_checkList}
                  key={index}
                >
                  <div className={styles.location_block}>
                    <div className="col-md-4 mb-3">
                      <label
                        className={styles.connection_label}
                        htmlFor="inputEmail4"
                      >
                        Brink Location Token
                      </label>
                      <input
                        type="text"
                        className={`form-control`}
                        disabled
                        value={el.locationToken}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label
                        className={styles.connection_label}
                        htmlFor="inputEmail4"
                      >
                        Wobot Location
                      </label>
                      <Select
                        placeholder="...Select"
                        value={{
                          label:
                            el.data == null
                              ? selectedWobot[index]?.label
                              : el?.location?.label,
                        }}
                        isDisabled={el.data !== null ? true : false}
                        onChange={(selectedOption) => {
                          if (selectedWobot.length) {
                            selectedWobot.map((el, i) => {
                              let array = [];
                              selectedWobot.map((el) => {
                                array.push(el);
                              });
                              array[index] = selectedOption;
                              setSelectedWobot(array);
                            });
                          } else {
                            setSelectedWobot([selectedOption]);
                          }
                        }}
                        options={props.data.wobotLocation}
                      />
                      {error.location ? (
                        <span className="error-msg">{error.location}</span>
                      ) : null}
                    </div>
                    <button
                      className={"btn btn-primary mt-2"}
                      onClick={() => {
                        OpenSelectedModal(index);
                        callApiClick(index);
                        setData(el);
                      }}
                    >
                      Manage Items
                    </button>
                  </div>
                </div>
              );
            })}

          {smallDiv.map((el, index) => {
            return (
              <div className={"mt-3 " + styles.jolt_checkList}>
                <div className={styles.location_block} key={index}>
                  <div className="col-md-4 mb-3">
                    <label
                      className={styles.connection_label}
                      htmlFor="inputEmail4"
                    >
                      Brink Location Token
                    </label>
                    <input
                      type="text"
                      className={`form-control`}
                      value={addLocationToken}
                      onChange={(e) => {
                        setAddLocationToken(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className={styles.connection_label}
                      htmlFor="inputEmail4"
                    >
                      Wobot Location
                    </label>
                    <Select
                      placeholder="...Select"
                      value={{
                        label: selectedWobot[index]?.label,
                      }}
                      // isDisabled={el.data !== null ? true : false}
                      onChange={(selectedOption) => {
                        if (selectedWobot.length) {
                          selectedWobot.map((el, i) => {
                            let array = [];
                            selectedWobot.map((el) => {
                              array.push(el);
                            });
                            array[index] = selectedOption;
                            setSelectedWobot(array);
                          });
                        } else {
                          setSelectedWobot([selectedOption]);
                        }
                      }}
                      options={props.data.wobotLocation}
                    />
                    {error.location ? (
                      <span className="error-msg">{error.location}</span>
                    ) : null}
                  </div>
                  <button
                    className={"btn btn-primary mt-2"}
                    onClick={() => {
                      if (addLocationToken.length) {
                        let obj = {
                          data: null,
                          location: "",
                          locationToken: addLocationToken,
                          brinkId: selectedWobot[index]?.value,
                          name: selectedWobot[index]?.label,
                        };
                        OpenSelectedModal(index);
                        callApiClick(index);
                        setData(obj);
                      } else {
                        showAlert("Select all fields!", "error");
                      }
                    }}
                  >
                    Manage Items
                  </button>
                </div>
              </div>
            );
          })}
          <div className={"ml-2"}>
            <button
              className={"btn btn-secondary mt-3  mb-2"}
              onClick={(e) => handleSmallDivCount(e)}
            >
              + Add Another
            </button>
          </div>
          {/* {console.log("data brinkLocation", data)} */}
          <BrinkLocationModal
            show={show}
            index={selectedModal}
            setShow={setShow}
            headerText={data?.name}
            brinkId={data?.brinkId}
            wobotlocation={
              data?.data !== null
                ? data?.location
                : selectedWobot[selectedModal]
            }
            successCallBack={() => props.data.successCallBack()}
            locationToken={data?.locationToken}
            onYes={() => {
              setShow(false);
              setSelectedWobot([]);
            }}
            footerText={""}
            onNo={() => {
              setShow(false);
            }}
            onHide={() => {
              setShow(false);
            }}
          />
          <div className={"mt-4"}>
            {" "}
            <div className={styles.jolt_info_location}>
              All the selected Items are added to CompanyName Jolt Checklists.
              You can then continue the configuration for these similar to other
              checklists and tasks.
            </div>
          </div>
        </div>
      </BlocksComponent>
    </div>
  );
};

export default BrinkLocation;
