import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import IconAdd from "../../../Common/Components/IconsComponent/IconAdd";
import IconSettings from "../../../Common/Components/IconsComponent/SidebarMain/IconSettings";
import ActionBlock from "../../../Common/Components/Molecule/ActionBlock";
import PageTitle from "../../../Common/Components/Molecule/PageTitle";
import SearchFilter from "../../../Common/Components/Molecule/SearchFilter";
import TabLink from "../../../Common/Components/Molecule/TabLink";
import { ADVANCED_SETTINGS, woCamRoutes } from "../../../Constants";
import { WOCAMADVANCECAMERASETTINGS, WOCAMMANAGECAMERA } from "../../../Constants/AccessList";
import Roles from "../../../Services/Roles";
import { useWoCamContext } from "../../Contexts/WocamContextProvider";
import { handleAddNewDevice } from "../../UseCases";
import styles from "./style.module.scss";

function WocamLayout({ children }) {
  const { manifest: { camera = 0, dvrs = [] } = {} } =
    useSelector((state) => state?.userData) || {};
  const {
    handleSearch,
    searchQuery,
    setShowCameraOfflineAlert,
    currentTabDataCount,
    setCurrentTabDataCount,
  } = useWoCamContext();
  const location = useLocation();
  const history = useHistory();

  const tabDataCounts = useMemo(
    () => [
      { name: "camera", count: currentTabDataCount?.cameras || 0 },
      { name: "nvr", count: currentTabDataCount?.dvrs || 0 },
    ],
    [currentTabDataCount]
  );
  const handleTabClick = () => {
    setShowCameraOfflineAlert(false);
    handleSearch("");
    setCurrentTabDataCount({});
  };
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper crumb-cursor-type">
        <PageTitle
          pageTitle="WoCam"
          showSubTitle
          subTitle="Onboard and manage your cameras and devices here"
          breadcrumb={[{ name: "WoCam" }]}
        >
          <ActionBlock showActionList={false} showActionBtn>
            {(Roles.authenticateViewAndAction(WOCAMMANAGECAMERA) && currentTabDataCount?.cameras) ||
            currentTabDataCount?.dvrs ? (
              <li className="list-inline-item ">
                <button
                  type="button"
                  className="btn btn-primary btn-sm btn-text-icon"
                  onClick={() => {
                    handleAddNewDevice({
                      currentPath: location.pathname + location.search,
                      history,
                    });
                  }}
                >
                  <span className="icon-md d-flex align-items-center">
                    <IconAdd /> Add New
                  </span>
                </button>
              </li>
            ) : null}
            <li className="list-inline-item">
              <SearchFilter value={searchQuery} handleUpdateValue={handleSearch} />
            </li>
          </ActionBlock>
        </PageTitle>
        <div
          className={` d-flex justify-content-between align-items-center ${styles.tab_container}`}
        >
          <TabLink
            onClick={() => handleTabClick()}
            currentPath={location.pathname}
            tabList={woCamRoutes}
            counts={tabDataCounts}
            className={styles.tab_list_container}
          />
          <div className="d-flex">
            {/* {showCameraOfflineAlert && (
              <InfoMessage
                type="warning"
                message={CAMERA_OFFLINE_ALERT_MESSAGE}
              />
            )} */}
            {Roles.authenticateViewAndAction(WOCAMADVANCECAMERASETTINGS) && (
              <Link to={ADVANCED_SETTINGS} className="ml-3">
                <button type="button" className={` btn btn-textIcon ${styles.stream_setting}`}>
                  <span>
                    <IconSettings />
                    Camera Stream Settings
                  </span>
                </button>
              </Link>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
WocamLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default WocamLayout;
