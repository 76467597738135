export const addEmpError = {
  name: "Please enter a name",
  email: "Please enter an email",
  emailSelect: "Please select an email",
  role: "Please select a role",
  mobile: "Please enter a correct mobile number",
  invalidEmail: "Please check the email address",
  selectedSupervisor: "Please select the member",
  location: "Please select at least 1 location",
};
export const profileError = {
  name: "Please enter the username",
  email: "Please enter the email",
  mobile: "Please enter correct mobile number",
  invalidEmail: "Please check the email address",
  currPassword: "Please enter current password",
  newPassword:
    "Password must be 8-20 characters long with at least one uppercase, one lowercase and a number",
  confirmNewPassword:
    "Password must be 8-20 characters long with at least one uppercase, one lowercase and a number",
  // role: "please select the role",
};

export const regionError = {
  region: "Please enter the region name",
  noSpecialChar: "Invalid input. Enter numbers or text characters.",
};
export const stationTypeError = {
  name: "Please enter the station type",
  invalid: "Invalid input. Enter numbers or text characters.",
};
export const cityError = {
  selectedRegion: "Please select a region",
  name: "Please enter the city name",
  noSpecialChar: "Invalid input. Enter numbers or text characters.",
};

export const branchError = {
  selectedRegion: "Please select a region",
  selectedLocation: "Please select a city",
  selectedTimezone: "Please select a timezone",
  name: "Please enter the location name",
  invalidName: "Invalid input. Enter only text characters.",
  noSpecialChar: "Invalid input. Enter numbers or text characters.",
  atleastOneAlphabet: "Invalid input. Enter atleast one alphabetic character.",
  noSpecialCharStart: "Name cannot begin with a contain special characters",
};

export const compDetError = {
  name: "Please enter  company name",
  industry: "Please select a industry",
  country: "Please select a country",
  email: "Please enter an email",
  invalidEmail: "Please check the email address",
};

export const scheduleError = {
  name: "Please enter a  schedule name",
  fromTime: "Please select the start time",
  tillTime: "Please select the end time",
  radio: "Please select the days",
  repeatSchedule: "Please Select the days",

  // email: "please enter the email address",
};
