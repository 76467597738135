import React, { useEffect, useState } from "react";
import {
  API_BASE_URL,
  API_CONFIG_SHOWLOADER,
  CAMERA_INTEGRATION,
} from "../../../../Constants";
import PageTitle from "../../../../Common/Components/Molecule/PageTitle";
import BlocksComponent from "../../../../Common/Components/Molecule/Block";
import CheckConnection from "./checkConnection";
import callApi from "../../../../Services/callApi";
import { showAlert } from "../../../../Services/showAlert";
import styles from "./brink.module.scss";
import { store } from "../../../../Store";
import BrinkLocation from "./brinkLocation";

function BrinkIntegration() {
  const [showLocation, setShowLocation] = useState(false);
  const [brinkLocation, setBrinkLocation] = useState([]);
  const [reFetch, setRefetch] = useState(false);
  const [wobotLocation, setWobotLocation] = useState([]);

  const { userData } = store.getState();
  const viewLocationBox = (value) => {
    setShowLocation(value);
  };
  useEffect(() => {
    const locationList = [];
    if (userData?.manifest?.regions) {
      userData?.manifest?.regions.map((el) => {
        if (el.type === "location") {
          locationList.push({
            value: el._id,
            label: el.area,
          });
        }
      });
      setWobotLocation(locationList);
    }
  }, []);
  console.log("wobotlocation", wobotLocation);
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper jolt-main-page-l">
        <PageTitle
          pageTitle="Settings"
          showSubTitle={false}
          breadcrumb={[
            { name: "Settings", link: CAMERA_INTEGRATION },
            { name: "Integrations" },
          ]}
        />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 ">
            <BlocksComponent>
              <CheckConnection
                data={{
                  showLocation: viewLocationBox,
                  setBrinkLocation,
                  reFetch,
                  setRefetch,
                }}
              />
            </BlocksComponent>
          </div>
          <div hidden={!showLocation} className="col-xl-12 col-lg-12 col-md-12">
            <BrinkLocation
              data={{
                brinkLocation,
                wobotLocation,
                successCallBack: () => {
                  setRefetch(true);
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrinkIntegration;
