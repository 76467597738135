import React from "react";

import Skeleton from "react-loading-skeleton";
const SettingsIntegration = () => {
  return (
    <div className={"ml-2 mt-2"}>
      <div className={"element-content-block mb-3"}>
        <p className={"text-primary mb-1"}>
          <Skeleton height={15} width={100} />
        </p>
        <h2 className={"mb-0 fw-500"}>
          <Skeleton width={400} />
        </h2>
      </div>
      <div className={"element-content-block"}>
        <p className={"text-primary mb-1"}>
          <Skeleton height={20} width={100} />
        </p>
        <h2 className={"mb-0 fw-500"}>
          <Skeleton width={400} />
        </h2>
      </div>
    </div>
  );
};

export default SettingsIntegration;
