import React from "react";
import Skeleton from "react-loading-skeleton";
import ContainerBlock from "../../../Molecule/ContainerBlock";

const HomeSkeleton = () => {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-sm-6 align-self-center">
            <div className="title">
              <h4>
                <Skeleton width={200} />
              </h4>
              <p className="mxw-300">
                <Skeleton count={2} />
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-4">
            <Skeleton height={100} className="mb-3" />
            <Skeleton height={180} />
          </div>
          <div className="col-lg-4">
            <Skeleton height={300} />
          </div>
          <div className="col-lg-4">
            <Skeleton height={300} />
          </div>
        </div>
        <div className="row mb-4">
          {Array(4)
            .fill()
            .map((e, index) => (
              <div className="col-md-3 col-sm-3 col-xl-3" key={index}>
                <Skeleton height={150} />
              </div>
            ))}
        </div>
        <div className="row">
          {Array(2)
            .fill()
            .map((e, index) => (
              <div className="col-xl-6 col-lg-6 col-md-6" key={index}>
                <Skeleton height={350} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeSkeleton;
