import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import {
  ADVANCED_SETTINGS,
  CAMERA_INTEGRATION,
  COMPANY_DETAILS,
  EMPLOYEE,
  JOLT,
  LOCATION,
  OPENEYE,
  SCHEDULE,
  SSO,
  STATION,
  SUBSCRIPTION_DETAILS,
  ZONE,
} from "../../../../Constants";
import {
  ACCESSDRIVETHRU,
  ACCESSPERSONSUITE,
  MANAGEDRIVETHRU,
  MANAGEPERSONSUITE,
  SETTINGSMANAGECOMPANYPROFILE,
  SETTINGSMANAGEINTEGRATIONS,
  SETTINGSMANAGEPLAN,
  SETTINGSVIEWEMPLOYEE,
  SETTINGSVIEWLOCATIONS,
  SETTINGSVIEWSCHEDULE,
  WOCAMADVANCECAMERASETTINGS,
} from "../../../../Constants/AccessList";
import Roles from "../../../../Services/Roles";
import IconAdvancedSetting from "../../IconsComponent/IconAdvancedSetting";
import IconLocation from "../../IconsComponent/IconLocation";
import IconPerson from "../../IconsComponent/IconPerson";
import IconUsers from "../../IconsComponent/IconUsers";
import {
  IconCompany,
  IconDrivethru,
  IconIntegration,
  IconPlansLimit,
} from "../../IconsComponent/Index";
import styles from "./StyleSidebarLink.module.scss";

import IconTime from "../../IconsComponent/IconTime";

const getSidebarList = ({ user }) => [
  {
    name: "Users",
    link: EMPLOYEE,
    icon: <IconUsers />,
    role: SETTINGSVIEWEMPLOYEE,
    subMenu: [],
  },
  {
    name: "Locations",
    link: LOCATION,
    icon: <IconLocation />,
    role: SETTINGSVIEWLOCATIONS,
    subMenu: [LOCATION, `${LOCATION}/regions`, `${LOCATION}/cities`],
  },
  {
    name: "Schedules",
    link: SCHEDULE,
    icon: <IconTime />,
    role: SETTINGSVIEWSCHEDULE,
    subMenu: [SCHEDULE, `${SCHEDULE}/shifts`],
  },
  {
    name: "DriveThru",
    link: STATION,
    icon: <IconDrivethru />,
    role: [MANAGEDRIVETHRU, ACCESSDRIVETHRU],
    subMenu: [],
  },
  {
    name: "Person Suite",
    link: ZONE,
    icon: <IconPerson />,
    role: [MANAGEPERSONSUITE, ACCESSPERSONSUITE],
    subMenu: [],
  },
  {
    name: "Integrations",
    link: CAMERA_INTEGRATION,
    icon: <IconIntegration />,
    role: SETTINGSMANAGEINTEGRATIONS,
    // hasAccess: !user?.accountManager,
    subMenu: [CAMERA_INTEGRATION, OPENEYE, JOLT, SSO],
  },
  {
    name: "Company",
    link: COMPANY_DETAILS,
    icon: <IconCompany />,
    role: SETTINGSMANAGECOMPANYPROFILE,
    subMenu: [],
  },
  {
    name: "Plan & Usage",
    link: SUBSCRIPTION_DETAILS,
    icon: <IconPlansLimit />,
    role: SETTINGSMANAGEPLAN,
    subMenu: [],
  },
  {
    name: "Advance",
    link: ADVANCED_SETTINGS,
    icon: <IconAdvancedSetting />,
    role: WOCAMADVANCECAMERASETTINGS,
    subMenu: [],
  },
];
function SidebarLinks({ handleRouteChange }) {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.userData) || {};

  const isActive = useCallback(
    (curr, menu) => curr || menu?.some((sub) => sub === pathname),
    [pathname]
  );
  const isCurrentLink = useCallback(
    (item) => item.link === pathname,
    [pathname]
  );
  const isAuthenticated = (role) => {
    if (Array.isArray(role)) {
      return role.every((r) => Roles.authenticateViewAndAction(r));
    }
    return Roles.authenticateViewAndAction(role);
  };
  const renderSettingMenus = () =>
    getSidebarList({ user }).map((item) => {
      if (isAuthenticated(item.role)) {
        return (
          <li
            key={item.name}
            className={isCurrentLink(item) ? `${styles.link_disabled}` : ""}
          >
            <NavLink
              to={item.link}
              isActive={(current) => isActive(current, item.subMenu)}
              activeClassName={`${styles.active_List}`}
              onClick={() => handleRouteChange()}
            >
              {item.icon}
              {item.name}
            </NavLink>
          </li>
        );
      }

      return null;
    });

  return (
    <div className={styles.sidebar_Block}>
      <ul className="mb-0">{renderSettingMenus()}</ul>
    </div>
  );
}
SidebarLinks.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
};
export default SidebarLinks;
