import React from "react";

const IconVideoZeroState = ({ height = 60, width = 60, fill = "#d2d2d2" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.9842 64.5485C73.9882 64.4398 73.9902 64.3307 73.9902 64.2211L73.9902 23.2979C73.9902 18.4548 70.0641 14.5287 65.221 14.5287L24.2978 14.5287C20.8861 14.5287 17.9294 16.477 16.4797 19.3218L21.3773 23.1737C21.4424 21.6169 22.7251 20.3749 24.2978 20.3749L65.221 20.3749C66.8353 20.3749 68.144 21.6836 68.144 23.2979L68.144 59.9552L73.9842 64.5485Z"
        fill={fill}
      />
      <path
        d="M51.2969 46.7051L56.4517 43.7595L35.9901 32.0672L35.9901 34.6665L51.2969 46.7051Z"
        fill={fill}
      />
      <path d="M35.9901 42.2057L45.7449 49.8777L35.9901 55.4518L35.9901 42.2057Z" fill={fill} />
      <path
        d="M21.3747 30.7108L21.3747 64.2211C21.3747 65.8354 22.6834 67.1442 24.2978 67.1442L65.2209 67.1442C65.8746 67.1442 66.4781 66.9296 66.965 66.5671L71.614 70.2235C70.0143 71.9266 67.7419 72.9903 65.2209 72.9903L24.2978 72.9903C19.4547 72.9903 15.5286 69.0642 15.5286 64.2211L15.5286 26.1129L21.3747 30.7108Z"
        fill={fill}
      />
      <line
        x1="7.04997"
        y1="20.3859"
        x2="78.3715"
        y2="76.4796"
        stroke={fill}
        stroke-width="5.92595"
      />
    </svg>
  );
};

export default IconVideoZeroState;
