import PropTypes from "prop-types"; // Import PropTypes library
import React from "react";

function IconWopipe({ color }) {
  return (
    <svg width="14" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 5V4.98857C16 3.89031 16.8903 3 17.9886 3H22.0114C23.1097 3 24 3.89031 24 4.98857V7.01143C24 8.10969 23.1097 9 22.0114 9H17.9886C16.8903 9 16 8.10969 16 7.01143V7H14V17H16V16.9886C16 15.8903 16.8903 15 17.9886 15H22.0114C23.1097 15 24 15.8903 24 16.9886V19.0114C24 20.1097 23.1097 21 22.0114 21H17.9886C16.8903 21 16 20.1097 16 19.0114V19H13C12.4477 19 12 18.5523 12 18V13.4358H9V13.5C9 14.6046 8.10457 15.5 7 15.5H2C0.89543 15.5 0 14.6046 0 13.5V11.5C0 10.3954 0.895431 9.5 2 9.5H7C8.08309 9.5 8.96509 10.3609 8.99899 11.4358H12V6C12 5.44772 12.4477 5 13 5H16ZM22 7H18V5H22V7ZM18 17V19H22V17H18ZM2 11.5H7V13.5H2L2 11.5Z"
        fill={color}
      />
    </svg>
  );
}

IconWopipe.propTypes = {
  color: PropTypes.string,
};

IconWopipe.defaultProps = {
  color: "#3766E8",
};

export default IconWopipe;
