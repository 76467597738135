export const getAllQueryVariables = () => {
  const queryString = window.location.search.substring(1);
  const queryVariables = {};

  queryString.split("&").forEach((param) => {
    const [key, value] = param.split("=");
    queryVariables[key] = decodeURIComponent(value);
  });

  return queryVariables;
};

export const getQueryVariables = () => {
  let query = window.location.search.substring(1);
  /**
   * For hash router
   * */
  //let query = window.location.hash.split("?")[1];
  let vars = query ? query.split("&") : "";
  const result = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return result;
};

const getQueryVariable = (variable) => {
  return getQueryVariables()[variable];
};

export default getQueryVariable;
