import React from "react";

function IconLocation({ width = "16", height = "16", color = "#545454" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C19.9788 15 12 22 12 22C12 22 3.97852 15 4.00004 10C4.02157 5 7.58176 2 12 2C16.4183 2 20.0212 5 20 10ZM18.0001 9.99152C17.9968 10.754 17.6736 11.7808 16.9905 13.0172C16.325 14.2219 15.4135 15.4557 14.4614 16.5877C13.5799 17.636 12.6939 18.5613 11.9967 19.2523C11.2962 18.5612 10.4057 17.635 9.52094 16.5857C8.56669 15.4541 7.65521 14.2217 6.99267 13.0192C6.31247 11.7847 5.99677 10.7637 6.00002 10.0086C6.00865 8.00535 6.71291 6.54162 7.72993 5.57388C8.76156 4.59225 10.2437 4 12 4C13.7583 4 15.2521 4.59356 16.2888 5.57642C17.3084 6.54301 18.0085 8.00023 18.0001 9.99152Z"
        fill={color}
      />
    </svg>
  );
}

export default IconLocation;
