import React from "react";

function IconPlaySquare() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_61660_2316)">
        <path d="M16 12L9 8V16L16 12Z" fill="#545454" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM5 4H19C19.5523 4 20 4.44771 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44771 4 5 4Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_61660_2316">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPlaySquare;
