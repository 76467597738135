// IconLabel.js

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import styles from "./Styles.module.scss";

function IconLabel({ icon, iconRight, children, className, iconClassName }) {
  const labelContainerRef = useRef(null);

  useEffect(() => {
    const labelInnerElement = labelContainerRef.current.querySelector(
      `.${styles["label-container-inner"]}`
    );

    if (labelInnerElement) {
      const childrenWidth = labelInnerElement.offsetWidth;
      labelContainerRef.current.style.width = `${childrenWidth}px`;
    }
  }, [children]);
  const renderIcon = (
    <div
      className={`${styles["icon-container"]} ${
        iconRight ? styles["icon-right"] : styles["icon-left"]
      } ${iconClassName}`}
    >
      {icon}
    </div>
  );
  return (
    <div className={`${styles["icon-label-container"]} ${className}`}>
      {!iconRight && renderIcon}
      <div className={`${styles["label-container"]}`} ref={labelContainerRef}>
        <div className={`${styles["label-container-inner"]}`}>{children}</div>
      </div>
      {iconRight && renderIcon}
    </div>
  );
}

IconLabel.propTypes = {
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconRight: PropTypes.bool,
};

IconLabel.defaultProps = {
  className: "",
  iconClassName: "",
  iconRight: false,
};

export default IconLabel;
