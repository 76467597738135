import React, { useEffect, useState } from "react";
import styles from "./brink.module.scss";
import { API_BASE_URL } from "../../../../Constants";
import Tag from "../../../../Common/Components/Molecule/Tag";
import { showAlert } from "../../../../Services/showAlert";
import callApi from "../../../../Services/callApi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConfirmModal from "../../../../Common/Components/Molecule/ModalBlock/ConfirmModal";
let defaultErrorLabels = {
  token: "",
};
const CheckConnection = (props) => {
  const [error, setError] = useState(defaultErrorLabels);

  const [token, setToken] = useState("");
  const [backendId, setBackEndId] = useState("");
  const [backendToken, setBackEndToken] = useState("");
  const [check, setChecked] = useState(false);
  const [textChange, setTextChange] = useState(true);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [action, setAction] = useState("");

  // const dummyResponse = {
  //   _id: "61b05f335d62c647518c55f6",
  //     BRINK: {
  //       isIntergated: true,
  //       _id: "6285cf30e208a249b08768e7",
  //       authToken: "3JW/eYxQAkGSGIv6SsO7Ww==",
  //       locations: [
  //       {
  //         name: "Wobot",
  //         location: {
  //           _id: "61dea87367cb8b2c2f5f2931",
  //           area: "delgi"
  //         },
  //         _id: "6285cf30e208a249b08768e8",
  //         locationToken: "NvRx/n9/t0GdI1bo56Tu/Q=="
  //       }
  //     ]
  //   }}

  const handleSaveSettings = async () => {
    try {
      const apiPayload = {
        authToken: token,
      };

      await callApi(
        `${API_BASE_URL}/brink/setup `,
        { method: "POST", body: JSON.stringify(apiPayload) },
        {
          showLoader: true,
          callManifest: false,
          loaderLabel: "",
        }
      ).then((response) => {
        if (response?.status === 200) {
          setChecked(true);
          props.data.showLocation(true);
          showAlert("Connected with Brink successfully");
        }
      });
    } catch (e) {
      showAlert("Something went wrong", "error");
    } finally {
      setTextChange(true);
    }
  };

  const getIdToken = async () => {
    try {
      await callApi(
        `${API_BASE_URL}/brink`,
        { method: "GET" },
        {
          showLoader: true,
          callManifest: false,
          loaderLabel: "",
        }
      ).then((response) => {
        if (response?.status === 200 && response?.data != null) {
          setBackEndToken(response?.data?.BRINK?.authToken);
          setBackEndId(response?.data?.BRINK?._id);
          setChecked(response?.data?.BRINK?.isIntegrated);
          props.data.setBrinkLocation(response?.data?.BRINK?.locations);
          props.data.showLocation(response?.data?.BRINK?.isIntegrated);
        } else {
          // showAlert("Add BRINK API Key");
        }
      });
    } catch (e) {
      showAlert(e);
    } finally {
      props.data.setRefetch(false);
      setTextChange(true);
    }
  };

  useEffect(() => {
    if (props?.data?.reFetch === true) {
      async function getData() {
        await getIdToken();
      }
      getData();
    }
  }, [props?.data?.reFetch]);

  useEffect(() => {
    async function getData() {
      await getIdToken();
    }
    getData();
  }, []);

  const pauseIntegration = async (value) => {
    let apiBody = {
      isIntegrated: value,
    };
    console.log("value", JSON.stringify(apiBody));
    try {
      await callApi(
        `${API_BASE_URL}/brink/switch`,

        {
          method: "PUT",
          body: JSON.stringify(apiBody),
        },
        {
          showLoader: true,
          callManifest: false,
          loaderLabel: "",
        }
      ).then((res) => {
        props.data.showLocation(res?.data?.isIntegrated);
        props?.data?.setRefetch(true);
      });
    } catch (e) {
      showAlert(e, "error");
    } finally {
      setAction("");
      setConfirmationModalVisibility(false);
      ToggleChange();
      props?.data?.setRefetch(false);
    }
  };
  const ToggleChange = () => {
    setChecked(!check);
  };

  return (
    <>
      <div className={styles.check_jolt_connection}>
        <div className={styles.check_delete}>
          <h4>Brink Integration</h4>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-left`}>
                {check ? "Disable integration" : "Enable integration"}
              </Tooltip>
            }
          >
            <span>
              <div className={styles.check_toggle_switch}>
                <div className="custom-control custom-switch">
                  {check ? (
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked
                      onClick={() => {
                        setAction("delete");
                        setConfirmationModalVisibility(true);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      onClick={() => {
                        pauseIntegration(true);
                      }}
                    />
                  )}

                  <label
                    className="custom-control-label"
                    htmlFor="customSwitches"
                  ></label>
                </div>
              </div>
            </span>
          </OverlayTrigger>
        </div>
        <p className={"mb-3"}>
          Connect your Brink account with Wobot.ai to get smart insights.
        </p>
        <div className="divider" />
        <div className={"mb-4 mt-3"}>
          <div className="col-md-4 mb-3">
            <label className={styles.connection_label} htmlFor="inputEmail4">
              Brink API Key
            </label>
            <input
              type="text"
              className="form-control mb-2"
              value={backendToken?.length && textChange ? backendToken : token}
              onChange={(event) => {
                setError({ ...error, token: "" });
                setToken(event.target.value);
                setTextChange(false);
              }}
              placeholder="Enter Brink API Key"
            />
            {/*//backendId.length ? backendId :*/}
            {error.token ? (
              <span className="error-msg">{error.token}</span>
            ) : null}
          </div>
        </div>
        <div className={"mb-2"}>
          <div className={styles.jolt_info}>
            These details are used to link your Brink account with Wobot.ai. All
            the credentials are saved in a secure and encrypted format.
          </div>
        </div>{" "}
        {backendToken?.length && backendId?.length && textChange ? (
          <footer className={styles.checkjoltfooter}>
            {/*<button className={"btn btn-default-icon"}>Cancel</button>*/}
            <button className={"btn btn-primary ml-2 mt-2"} disabled>
              Save Settings
            </button>
          </footer>
        ) : (
          <footer className={styles.checkjoltfooter}>
            <button
              className={"btn btn-primary ml-2 mt-2"}
              onClick={handleSaveSettings}
            >
              Save Settings
            </button>
          </footer>
        )}
        <ConfirmModal
          visibility={confirmationModalVisibility}
          setVisiblity={setConfirmationModalVisibility}
          modalContent={
            <>
              <div className={"confirm-message d-block"}>
                <h6> Disable integration?</h6>

                <p className={"mb-0"}>
                  Disabling the integration will stop all the data sync between
                  Brink and Wobot.ai. No new events, tickets or videos will be
                  available for Brink based tasks.
                  <br /> <br />
                  Are you sure you want to continue?
                </p>
              </div>
            </>
          }
          secondaryBtn={"Cancel"}
          primaryBtnDelete={"Yes,  Disable integration"}
          primaryBtnConfirm={"Yes, Enable integration"}
          onYes={() => pauseIntegration(false)}
          ActionCondition={action === "confirm" ? "confirm" : "delete"}
          onNo={() => {
            setConfirmationModalVisibility(false);
            setAction("");
          }}
          onHide={() => {
            setConfirmationModalVisibility(false);
            setAction("");
          }}
        />
      </div>
    </>
  );
};

export default CheckConnection;
