import React, { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./brink.module.scss";
import Select from "react-select";
import {
  IconTrash,
  IconLShape,
} from "../../../../Common/Components/IconsComponent/Index";
import { showAlert } from "../../../../Services/showAlert";
import callApi from "../../../../Services/callApi";
import { API_BASE_URL } from "../../../../Constants";
import ConfirmModal from "../../../../Common/Components/Molecule/ModalBlock/ConfirmModal";
import { colourStyles } from "../../../../Services/colourStyles";
import SettingsIntegration from "../../../../Common/Components/SkeletonComponent/DetailsSkeleton/SettingsSkeleon/SettingIntegrations";
import ConfidenceLevel from "../../../../Common/Components/Molecule/Confidence";

let defaultErrorLabels = {
  companyId: "",
  alert: "",
};

const BrinkLocationModal = (props) => {
  console.log("modal props", props);
  const [smallDiv, setSmallDiv] = useState([1]);
  const [error, setError] = useState(defaultErrorLabels);
  const [brinkLocation, setBrinkLocation] = useState([]);
  const [selectedBrinkLocation, setSelectedBrinkLocation] = useState();
  const [selectedCamera, setSelectedCamera] = useState([]);
  const [alert, setAlert] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState([]);

  const [addedCheckList, setAddedCheckList] = useState([]);
  const [tills, setTills] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [action, setAction] = useState("");
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [loading, setLoading] = useState(false);

  // const AddItem = (el, selectedItem) => {
  //   let oldTills = tills;
  //   let newTills = oldTills.map((item) => {
  //     if (item.tillId === el) {
  //       item.camera = selectedItem.length
  //         ? [...selectedItem.map((e) => e.value)]
  //         : [];
  //     }
  //     return item;
  //   });
  //   setTills(newTills);
  // };
  const [selectedOptions, setSelectedOptions] = useState({
    tills: [],
  });
  const AddItem = (state, el, selectedItem) => {
    if (selectedOptions.tills?.length) {
      let filterdItem = selectedOptions.tills?.find(
        (i) => i.tillId === el.tillId
      );
      let restItem = selectedOptions.tills?.filter(
        (i) => i.tillId !== el.tillId
      );
      if (filterdItem) {
        filterdItem.camera = [...selectedItem.map((e) => e.value)];
        return { tills: [...restItem, { ...filterdItem }] };
      } else {
        return {
          tills: [
            ...state.tills,
            {
              tillId: el.tillId,
              camera: [...selectedItem.map((e) => e.value)],
            },
          ],
        };
      }
    } else {
      return {
        tills: [
          {
            tillId: el.tillId,
            camera: [...selectedItem.map((e) => e.value)],
          },
        ],
      };
    }
  };
  const dummyResponse = [
    {
      tillId: "65537",
      camera: {
        _id: "62848a0677fd3a98129549d9",
        company: "61b05f335d62c647518c55f6",
        location: "61dea87367cb8b2c2f5f2931",
        tillId: "65537",
        __v: 0,
        camera: [
          {
            _id: "62555515e62c4b239fc2daa8",
            camera: "uni mediaserver",
          },
          {
            _id: "61f8ce163572260927d794f1",
            camera: "ROI motion recording",
          },
          {
            _id: "61f8caaa3ff5720921f2f8e5",
            camera: "sample streams",
          },
        ],
        city: "61dea87367cb8b2c2f5f2930",
        created_at: "2022-05-18T05:58:18.696Z",
        region: "61dea87367cb8b2c2f5f292d",
        updated_at: "2022-05-19T04:55:19.482Z",
        locationToken: "NvRx/n9/t0GdI1bo56Tu/Q==",
      },
    },
    {
      tillId: "65538",
      camera: {
        _id: "62848a0677fd3a98129549d9",
        company: "61b05f335d62c647518c55f6",
        location: "61dea87367cb8b2c2f5f2931",
        tillId: "65538",
        __v: 0,
        camera: [
          {
            _id: "62555515e62c4b239fc2daa8",
            camera: "uni mediaserver",
          },
          {
            _id: "61f8ce163572260927d794f1",
            camera: "ROI motion recording",
          },
          {
            _id: "61f8caaa3ff5720921f2f8e5",
            camera: "sample streams",
          },
        ],
        city: "61dea87367cb8b2c2f5f2930",
        created_at: "2022-05-18T05:58:18.696Z",
        region: "61dea87367cb8b2c2f5f292d",
        updated_at: "2022-05-19T04:55:19.482Z",
        locationToken: "NvRx/n9/t0GdI1bo56Tu/Q==",
      },
    },
  ];

  const callCheckList = async () => {
    try {
      setLoading(true);
      const apiPayload = {
        locationToken: props.locationToken,
        location: props.brinkId,
      };

      await callApi(
        `${API_BASE_URL}/brink/fetch/tills`,
        { method: "POST", body: JSON.stringify(apiPayload) },
        {
          showLoader: true,
          callManifest: false,
          loaderLabel: "",
        }
      ).then((response) => {
        if (response?.status === 200) {
          // let tills = [];
          // response?.data.forEach((element) => {
          //   let obj = {
          //     tillId: element.tillId,
          //     camera: [],
          //   };

          //   tills.push(obj);
          // });

          setBrinkLocation(response?.data);
          // setTills(tills);
        }
      });
    } catch (e) {
      showAlert("Something went wrong", "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (props.show) {
      async function getData() {
        await callCheckList();
      }
      getData();
    }
  }, [props.show]);

  const submitSettings = async () => {
    const apiPayload = {
      tills: selectedOptions.tills,
      locationToken: props?.locationToken,
      location: props?.brinkId,
    };

    await callApi(`${API_BASE_URL}/brink/setup/tills/cameras`, {
      method: "POST",
      body: JSON.stringify(apiPayload, {
        showLoader: true,
        callManifest: false,
        loaderLabel: "Saving Task",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          showAlert(res.message || "Success");
          setAction("");
          setConfirmationModalVisibility(false);
          props.onYes();
          props.successCallBack();
        }
      })
      .catch(() => {
        showAlert("error in adding checklist", "error");
        setAction("");
        setConfirmationModalVisibility(false);
      });
  };

  const handleSmallDivCount = (e) => {
    // e.preventDefault();
    if (
      smallDiv.length === selectedBrinkLocation.length &&
      selectedBrinkLocation.length === selectedAlert.length
    ) {
      setSmallDiv((arr) => [...arr, smallDiv + 1]);
    } else {
      showAlert("Please enter all details", "warning");
    }
  };

  const onDeleteConfrimClick = async () => {
    const apiPayload = {
      tills: [
        {
          tillId: deleteId,
          camera: [],
        },
      ],
      locationToken: props?.locationToken,
      location: props?.brinkId,
    };
    await callApi(
      `${API_BASE_URL}/brink/setup/tills/cameras`,
      {
        method: "POST",
        body: JSON.stringify(apiPayload),
      },
      {
        showLoader: true,
        callManifest: false,
        loaderLabel: "Saving Task",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          showAlert(res.message);
          setAction("");
          setConfirmationModalVisibility(false);
          props.onYes();
          props.successCallBack();
        }
      })
      .catch(() => {
        showAlert("error in deleting checklist", "error");
        setAction("");
        setConfirmationModalVisibility(false);
      });
  };

  return (
    <>
      <Modal
        className="action-popup"
        show={props.show}
        onHide={props.onHide}
        size="lg"
      >
        <Modal.Body>
          <div className="modal-popup mt-2">
            <h5 className="fw-600 ">{`${props?.headerText} - ${props?.wobotlocation?.label}`}</h5>
            <p className={"mb-3"}>
              Select which POS machines you want to monitor
            </p>

            <div className="divider" />
            {loading ? (
              <SettingsIntegration />
            ) : (
              <div>
                <>
                  {brinkLocation.map((el, index) => {
                    return (
                      <>
                        <div className={styles.modal_select_div} key={index}>
                          <div className="col-md-5 mb-3">
                            <label htmlFor="inputEmail4">Till Id</label>
                            <Select
                              value={{ label: el.tillId }}
                              isDisabled={true}
                            />
                          </div>
                          <div className="col-md-5 mb-3">
                            <label htmlFor="inputEmail4">
                              Cameras Covering
                            </label>
                            <Select
                              options={el.camera.camera.map((item) => {
                                return {
                                  value: item._id,
                                  label: item.camera,
                                };
                              })}
                              onChange={(selectedItem) => {
                                // AddItem(el.tillId, selectedItem);
                                setSelectedOptions((state) =>
                                  AddItem(state, el, selectedItem)
                                );
                              }}
                              // isDisabled={true}
                              isMulti
                            />
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-left`}>Delete task</Tooltip>
                            }
                          >
                            <span
                              className={"mt-2 ml-4 cursor-pointer"}
                              onClick={() => {
                                setConfirmationModalVisibility(true);
                                setDeleteId(el.tillId);
                                setAction("delete");
                              }}
                            >
                              {" "}
                              <IconTrash />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </>
                    );
                  })}
                </>
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="divider" />
        <Modal.Footer>
          <div className="align-right">
            <button
              variant="secondary"
              className="btn btn-link fw-400 fs-14 btn-sm"
              onClick={props.onNo}
            >
              Cancel
            </button>
            <button
              variant="primary"
              className="btn btn-primary ml-2"
              onClick={async () => {
                if (selectedOptions.tills.length) {
                  await submitSettings();
                } else {
                  showAlert("Select fields", "error");
                }
              }}
            >
              Save Settings
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        visibility={confirmationModalVisibility}
        setVisiblity={setConfirmationModalVisibility}
        modalContent={
          <>
            <div className={"confirm-message d-block"}>
              <h6>Delete Task from Wobot.ai?</h6>

              <p className={"mb-0"}>
                Deleting the task from here will stop the task processing for
                this location and remove all its settings.
                <br />
                In case you want to re-run the task, you'll need to add it here
                again.
              </p>
            </div>
          </>
        }
        secondaryBtn={"Cancel"}
        primaryBtnDelete={"Yes, Delete Task"}
        primaryBtnConfirm={"Yes, Save Task"}
        onYes={onDeleteConfrimClick}
        ActionCondition={action == "confirm" ? "confirm" : "delete"}
        onNo={() => {
          setConfirmationModalVisibility(false);
          setDeleteId("");
          setAction("");
        }}
        onHide={() => {
          setConfirmationModalVisibility(false);
          setDeleteId("");
          setAction("");
        }}
      />
    </>
  );
};

export default BrinkLocationModal;
