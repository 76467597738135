import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  IconArrowDown,
  IconCompany,
  IconLogout,
  IconProfile,
} from "../../../../Common/Components/IconsComponent/Index";
import {
  API_BASE_URL,
  DEMO_URL,
  EMPLOYEE_PROFILE,
  LOGOUT,
} from "../../../../Constants";
import callApi from "../../../../Services/callApi";
import { showAlert } from "../../../../Services/showAlert";
import { toggleNavbarAction } from "../../../../Store/actions";
import NavbarComponent from "./NavbarComponent";
import "./style.scss";

const headerStyle = {
  paddingTop: "45px",
};

export function HeaderComponent(props) {
  const history = useHistory();
  const notification = useHistory();

  const [dropdownAnimation, setDropdownAnimation] = useState("");
  const [dropdownNotification, setDropdownNotification] = useState("");
  const [arrowPosition, setArrowPosition] = useState("--down");

  /* Notification Data UseState */
  const [notificationData, setNotificationData] = useState([]);

  const dropDownRef = useRef("");

  // Dropdown handler
  const dropDownHandler = () => {
    setDropdownAnimation((prev) => (prev === "" ? "--animation" : ""));
    setArrowPosition((prev) => (prev === "--down" ? "--up" : "--down"));
  };

  // Dropdown handler
  const dropDownNotificationHandler = () => {
    setDropdownNotification((prev) => {
      return prev === "" ? "--animation" : "";
    });
  };

  /* GET API CALL for Notification */
  const getNotificationUpdates = async () => {
    try {
      let notificationUpdates = await callApi(
        `${API_BASE_URL}/notification/get?seen=true`
      );
      if (notificationUpdates.status === 200) {
        // console.log(notificationUpdates.data);
        setNotificationData(notificationUpdates.data);
      }
    } catch (e) {
      showAlert(e, "error");
    }
  };

  /* UseEffect Call for Notification */
  useEffect(() => {
    try {
      getNotificationUpdates();
    } catch (e) {
      showAlert(e, "error");
    }
  }, []);

  return (
    <header
      className={`navbar ${
        props?.alertData?.length > 0 ? "header-with-alert" : "header"
      } justify-content-end`}
    >
      <NavbarComponent />

      <OutsideClickHandler
        onOutsideClick={(event) => {
          if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
          ) {
            setDropdownAnimation("");
            setArrowPosition("--down");
          }
        }}
      >
        <div className="header__dropdown-logo" onClick={dropDownHandler}>
          <div className="text-right mr-2">
            <div className="drop-profile--thumbHeader mr-1">
              <Avatar
                name={props?.userData?.user?.name}
                src={props?.userData?.user?.displayImage}
                size="30"
                round="50px"
                color="#3766E8"
                textSizeRatio={0}
              />
            </div>
          </div>
          <span className={`arrow-icon${arrowPosition}`}>
            <IconArrowDown />
          </span>
        </div>
      </OutsideClickHandler>

      <div
        className={`header__dropdown-container${dropdownAnimation}`}
        ref={dropDownRef}
      >
        <div className="drop-wrapper--profile drop-flex-wrapper">
          <div className="drop-profile--thumb">
            {/* <img
                src={
                  props?.userData?.user?.displayImage ||
                  `/assets/images/user_thumb.svg`
                }
                className={"img-fluid"}
              /> */}
            <Avatar
              name={props?.userData?.user?.name}
              src={props?.userData?.user?.displayImage}
              size="48"
              round="50%"
              color="#3766E8"
            />
          </div>
          <div className="drop-profile--content">
            <ul>
              <li>
                {props &&
                props.userData &&
                props.userData.user &&
                props.userData.user.name
                  ? props.userData.user.name
                  : "-"}
              </li>
              <li>
                {props &&
                props.userData &&
                props.userData.user &&
                props.userData.user.email
                  ? props.userData.user.email
                  : "-"}
              </li>
              {/* <li>
                  {props &&
                  props.userData &&
                  props.userData.user &&
                  props.userData.user.username
                    ? props.userData.user.username
                    : "-"}
                </li> */}
            </ul>
          </div>
        </div>

        <div className="drop-wrapper--list">
          <ul>
            <li>
              <IconCompany />{" "}
              <span>
                {props &&
                props.userData &&
                props.userData.manifest &&
                props.userData.manifest.company &&
                props.userData.manifest.company.name
                  ? props.userData.manifest.company.name
                  : "-"}
              </span>
            </li>
            <li
              onClick={() => {
                history.push(EMPLOYEE_PROFILE);
              }}
            >
              <IconProfile /> <span>Account Settings</span>
            </li>
            {window.location.origin !== DEMO_URL ? (
              <li
                onClick={() => {
                  history.push(LOGOUT);
                }}
              >
                <IconLogout /> <span>Logout</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => ({ ...state, userData: state?.userData });
const mapDispatchToProps = (dispatch) => ({
  toggleNavbarComponent: () => {
    dispatch(toggleNavbarAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
