import React from "react";
import Layout from "../../Common/Containers/_layouts/Home";
import Helmet from "react-helmet";
import BrinkIntegration from "../Components/IntegrationComponent/BrinkIntegration";

const BrinkPosIntegration = () => {
  return (
    <Layout>
      <Helmet>
        <title>{`Brink | Wobot.ai`}</title>
      </Helmet>

      <BrinkIntegration />
    </Layout>
  );
};

export default BrinkPosIntegration;
