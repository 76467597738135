import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  CHECKLIST,
  COMPLIANCE_CONFIG,
  LIVE_MONITORING,
  PAGE404,
  TASK_TYPE,
  TASK_TYPE_EVENTS,
} from "../Constants";
import TaskDetailSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/TaskDetailSkeleton/TaskDetailSkeleton";
import TaskTypeEventSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/TaskTypeEventSkeleton";
import MainTaskDetailSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/TaskDetailSkeleton/MainTaskDetailSkeleton";
import Layout from "../Common/Containers/_layouts/Home";
import MainTaskSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/MainTask";
import LiveMonitoringSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/LiveMonitoringSkeleton";

const List = lazy(() => import("./Containers/List"));
const Compliance = lazy(() => import("./Containers/Compliance"));
const Events = lazy(() => import("./Containers/Events/index"));
const TaskType = lazy(() => import("./Containers/TaskType/index"));
const LiveMonitoring = lazy(() => import("./Containers/LiveMonitoring"));

function TaskModule() {
  return (
    <Layout>
      <Switch>
        <Route path={TASK_TYPE} exact>
          <Suspense fallback={<TaskDetailSkeleton />}>
            <TaskType />
          </Suspense>
        </Route>
        <Route path={TASK_TYPE_EVENTS} exact>
          <Suspense fallback={<TaskTypeEventSkeleton />}>
            <Events />
          </Suspense>
        </Route>
        <Route path={COMPLIANCE_CONFIG} exact>
          <Suspense fallback={<MainTaskDetailSkeleton />}>
            <Compliance />
          </Suspense>
        </Route>
        <Route path={CHECKLIST}>
          <Suspense fallback={<MainTaskSkeleton />}>
            <List />
          </Suspense>
        </Route>
        <Route exact path={LIVE_MONITORING}>
          <Suspense fallback={<LiveMonitoringSkeleton />}>
            <LiveMonitoring />
          </Suspense>
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default TaskModule;
