import React from "react";
import "./style.scss";

const ConfidenceLevel = (props) => {
  switch (props.confidence) {
    case "high": {
      return (
        <div className={"confidence-level"}>
          <div className={"green-dot"}></div>
          <caption className={"confidence-high"}>High</caption>
        </div>
      );
      break;
    }
    case "medium": {
      return (
        <div className={"confidence-level"}>
          <div className={"blue-dot"}></div>
          <caption className={"confidence-medium"}>Medium</caption>
        </div>
      );
      break;
    }
    case "low": {
      return (
        <div className={"confidence-level"}>
          <div className={"orange-dot"}></div>
          <caption className={"confidence-low"}>Low</caption>
        </div>
      );
      break;
    }
    case "unknown": {
      return (
        // <div className={"confidence-level"}>
        //   <div className={"purple-dot"}></div>
        //   <caption className={"confidence-unknown"}>Unknown</caption>
        // </div>
        ""
      );
      break;
    }
    default: {
      break;
    }
  }
};

export default ConfidenceLevel;
