import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import IconLabel from "../IconLabel";
import styles from "./StyleContainer.module.scss";

const heading = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
};
function Heading({ type, children, ...props }) {
  return React.createElement(heading[type] || heading.h6, props, children);
}

Heading.defaultProps = {
  type: "h6",
};

function ContainerBlock({
  className = "",
  containerTitleIcon,
  containerHeader,
  classNameHeadingWrapper = "",
  headingLeftIcon,
  classNameHeading = "",
  titleType,
  headingTitle,
  headingDescription,
  descriptionContent,
  children = null,
  rightAlignContent,
  topRightAction,
  topRightContent,
  rightContent = null,
  disabledClose,
  btnHidden,
  btnOnClick,
  btnIcon,
  headingRightIcon,
  headingRightChildren,
  btnText,
  btnIconLeft,
  btnIconRight,
  btnClassName = "",
}) {
  return (
    <div className={`${styles.container_block} ${className}`}>
      <div className={styles.container_section}>
        {containerHeader && (
          <div className={styles.header_container}>
            <div
              className={`${styles.header_wrapper} ${classNameHeadingWrapper}`}
            >
              <div className={styles.header_content}>
                {containerTitleIcon && headingLeftIcon}
                {headingTitle?.length > 25 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{headingTitle}</Tooltip>}
                  >
                    <Heading
                      className={`mb-0 mr-2 ${classNameHeading}`}
                      type={titleType}
                    >
                      {headingTitle}
                    </Heading>
                  </OverlayTrigger>
                ) : (
                  <Heading
                    className={`mb-0 mr-2 ${classNameHeading}`}
                    type={titleType}
                  >
                    {headingTitle}
                  </Heading>
                )}

                {headingRightIcon && headingRightChildren}
              </div>
              {headingDescription && (
                <p className="mb-0">{descriptionContent}</p>
              )}
            </div>
            {rightAlignContent && (
              <div>
                {topRightAction && (
                  <button
                    disabled={disabledClose}
                    hidden={btnHidden}
                    onClick={btnOnClick}
                    type="button"
                    className={`${btnClassName} btn btn-sm btn-single-icon`}
                  >
                    <IconLabel iconRight={btnIconRight} icon={btnIcon}>
                      {btnText}
                    </IconLabel>
                  </button>
                )}
                {topRightContent && rightContent}
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
ContainerBlock.propTypes = {
  className: PropTypes.string,
  containerHeader: PropTypes.bool,
  containerTitleIcon: PropTypes.bool,
  headingLeftIcon: PropTypes.string,
  classNameHeadingWrapper: PropTypes.string,
  titleType: PropTypes.string,
  headingTitle: PropTypes.string,
  headingDescription: PropTypes.bool,
  descriptionContent: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  rightAlignContent: PropTypes.bool,
  topRightAction: PropTypes.bool,
  topRightContent: propTypes.bool,
  rightContent: PropTypes.element,
  disabledClose: PropTypes.func,
  btnHidden: PropTypes.func,
  btnOnClick: PropTypes.func,
  btnIcon: PropTypes.string,
  headingRightIcon: propTypes.bool,
  headingRightChildren: PropTypes.element,
  classNameHeading: PropTypes.string,
  btnText: PropTypes.string,
  btnIconLeft: PropTypes.bool,
  btnIconRight: PropTypes.bool,
  btnClassName: PropTypes.string,
};
ContainerBlock.defaultProps = {
  className: "",
  containerHeader: false,
  containerTitleIcon: false,
  headingLeftIcon: null,
  classNameHeadingWrapper: "",
  titleType: null,
  headingTitle: "",
  headingDescription: false,
  descriptionContent: "",
  children: null,
  rightAlignContent: false,
  topRightAction: false,
  topRightContent: false,
  rightContent: null,
  disabledClose: undefined,
  btnHidden: null,
  btnOnClick: () => {},
  btnIcon: null,
  headingRightIcon: false,
  headingRightChildren: null,
  btnText: null,
  btnIconLeft: false,
  classNameHeading: "",
  btnIconRight: false,
  btnClassName: "",
};
export default ContainerBlock;
