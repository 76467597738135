import React, { memo } from "react";
import { useSelector } from "react-redux";
import useAssistantSearch from "../../Hook/useAssistantSearch";
import Header from "../Header";
import MessageInput from "../MessageInput";
import MessageList from "../MessageList";
import styles from "../Styles.module.scss";

function AssistantContainer() {
  const {
    smartAssistant: {
      isAssistantOpen = false,
      messages = [],
      isInFullViewMode,
      selectedOptions: { search = {}, location = {} } = {},
    } = {},
  } = useSelector((state) => state) || {};
  const { isLoadingResponse, handleMessageSubmit } = useAssistantSearch({
    search,
    location,
  });
  return (
    <div
      className={`${styles["chat-widget"]} ${isAssistantOpen ? styles["is-open"] : ""} ${
        isInFullViewMode ? styles["is-full-view"] : ""
      }`}
    >
      <Header
        isShowZeroState={messages ? !messages?.length : true}
        isInFullViewMode={isInFullViewMode}
      />
      <MessageList
        messages={messages}
        isLoadingResponse={isLoadingResponse}
        cameraId={location?.camera || ""}
      />
      <MessageInput
        handleMessageSubmit={handleMessageSubmit}
        isSubmitting={isLoadingResponse}
        searchOption={{ search: search?.value, location: location?.selector }}
      />
    </div>
  );
}

export default memo(AssistantContainer);
