import React from "react";
import { toast } from "react-toastify";
import {
  IconErrorToast,
  IconSuccessToast,
  IconWarningToast,
} from "../Common/Components/IconsComponent/Index";
import Service from "./Service";

const showIcon = (type) => {
  switch (type) {
    case "info":
      return <IconSuccessToast />;
    case "error":
      return <IconErrorToast />;
    case "warning":
      return <IconWarningToast />;
    case "success":
      return <IconSuccessToast />;

    default:
      return <IconSuccessToast />;
  }
};

function toSentenceCase(inputString, includedWords = ["wobot", "support"]) {
  if (inputString && inputString.length && typeof inputString === "string") {
    let capitalizeNext = true;
    let string = inputString.toLowerCase();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < string.length; i++) {
      if (/[.!?](?=\s)/.test(`${string[i]}${string[i + 1]}`)) {
        capitalizeNext = true;
      } else if (/[a-zA-Z']/i.test(string[i])) {
        if (capitalizeNext || i === 0) {
          string = `${string.slice(0, i)}${string.charAt(i).toUpperCase()}${string.slice(i + 1)}`;
          capitalizeNext = false;
        }

        const wordStartIndex = i;
        let wordEndIndex = i + 1;
        while (wordEndIndex < string.length && /[a-zA-Z']/i.test(string[wordEndIndex])) {
          // eslint-disable-next-line no-plusplus
          wordEndIndex++;
        }

        const word = string.substring(wordStartIndex, wordEndIndex);
        const isIncludedWord = includedWords.some(
          (includedWord) => word.toLowerCase() === includedWord.toLowerCase()
        );

        if (isIncludedWord) {
          if (!/[.!?]$/.test(string.slice(0, wordStartIndex))) {
            string = `${string.slice(0, wordStartIndex)}${word
              .charAt(0)
              .toUpperCase()}${string.slice(wordStartIndex + 1)}`;
          }
        }

        i = wordEndIndex - 1;
      }
    }

    return string;
  }

  return "";
}

export const showAlert = (message, type = "info", isFormat = true) => {
  if (typeof message !== "string") {
    return;
  }

  if (!Service.isOnline()) {
    window.location.reload();
    return;
  }
  toast.dismiss();
  const formattedMessage = isFormat ? toSentenceCase(message) : message;
  toast.info(formattedMessage, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    closeButton: true,
    closeOnClick: false,
    pauseOnHover: false,
    type,
    icon: showIcon(type),
    hideProgressBar: true,
  });
};

// IconWarningToast,
//   IconSuccessToast,
//   IconInfoToast,
//   IconErrorToast,
