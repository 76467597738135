import React from "react";

function IconPosInsights() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4 7C6.84771 7 6.4 7.44772 6.4 8V12C6.4 12.5523 6.84771 13 7.4 13H16.4C16.9523 13 17.4 12.5523 17.4 12V8C17.4 7.44772 16.9523 7 16.4 7H7.4ZM7.8 8.4V11.6H16V8.4H7.8Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4 14C6.84771 14 6.4 14.4477 6.4 15V16C6.4 16.5523 6.84771 17 7.4 17H8.4C8.95228 17 9.4 16.5523 9.4 16V15C9.4 14.4477 8.95228 14 8.4 14H7.4ZM8.4 15H7.4V16H8.4V15Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4 18C6.84771 18 6.4 18.4477 6.4 19V20C6.4 20.5523 6.84771 21 7.4 21H8.4C8.95228 21 9.4 20.5523 9.4 20V19C9.4 18.4477 8.95228 18 8.4 18H7.4ZM8.4 19H7.4V20H8.4V19Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4 14C10.8477 14 10.4 14.4477 10.4 15V16C10.4 16.5523 10.8477 17 11.4 17H12.4C12.9523 17 13.4 16.5523 13.4 16V15C13.4 14.4477 12.9523 14 12.4 14H11.4ZM12.4 15H11.4V16H12.4V15Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4 18C10.8477 18 10.4 18.4477 10.4 19V20C10.4 20.5523 10.8477 21 11.4 21H12.4C12.9523 21 13.4 20.5523 13.4 20V19C13.4 18.4477 12.9523 18 12.4 18H11.4ZM12.4 19H11.4V20H12.4V19Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4 14C14.8477 14 14.4 14.4477 14.4 15V16C14.4 16.5523 14.8477 17 15.4 17H16.4C16.9523 17 17.4 16.5523 17.4 16V15C17.4 14.4477 16.9523 14 16.4 14H15.4ZM16.4 15H15.4V16H16.4V15Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4 18C14.8477 18 14.4 18.4477 14.4 19V20C14.4 20.5523 14.8477 21 15.4 21H16.4C16.9523 21 17.4 20.5523 17.4 20V19C17.4 18.4477 16.9523 18 16.4 18H15.4ZM16.4 19H15.4V20H16.4V19Z"
        fill="#545454"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4 1C7.4 0.447715 7.84771 0 8.4 0H15.4C15.9523 0 16.4 0.447715 16.4 1V3H18.4C19.5046 3 20.4 3.89543 20.4 5V22C20.4 23.1046 19.5046 24 18.4 24H5.4C4.29543 24 3.4 23.1046 3.4 22V5C3.4 3.89543 4.29543 3 5.4 3H7.4V1ZM8.6 5.3V1.2H15.2V5.3H8.6ZM16.4 4.7V5.5H17.4C17.6761 5.5 17.9 5.72386 17.9 6C17.9 6.27614 17.6761 6.5 17.4 6.5H6.4C6.12386 6.5 5.9 6.27614 5.9 6C5.9 5.72386 6.12386 5.5 6.4 5.5H7.4V4.7H5.4C5.23431 4.7 5.1 4.83431 5.1 5V22C5.1 22.1657 5.23431 22.3 5.4 22.3H18.4C18.5657 22.3 18.7 22.1657 18.7 22V5C18.7 4.83431 18.5657 4.7 18.4 4.7H16.4Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconPosInsights;
