import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
// ProSidebar style import
import "react-pro-sidebar/dist/css/styles.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { changeActiveSidebarAction } from "../../../../../Store/actions";
import { IconClose, IconHelp } from "../../../../Components/IconsComponent/Index";
import HelpSection from "../HelpSection/HelpSection";
// Style Import
import "./style.scss";
// Constants Import
import { CAMERA, WALK_IN, hasSubRoutes, routes_hover } from "../../../../../Constants";
// Import Bootstrap
import { carWashConfig } from "../../../../../Drivethru/Utils";
import Roles from "../../../../../Services/Roles";
import Service, {
  checkPersonSuite,
  isOnlyGroupedRoutesExistsInSubMenu,
} from "../../../../../Services/Service";
import updateManifest from "../../../../../Services/updateManifest";
import { setIsOpenAssistant } from "../../../../../SmartAssistant/State/assistantAction";
import { getCurrentCompanyWithTheirAIFeatures } from "../../../../../SmartAssistant/Utils";
import IconRole from "../../../../Components/IconsComponent/IconRole";
import IconSmartAssistant from "../../../../Components/IconsComponent/IconSmartAssistant";
import RaiseATicket from "../RaiseATicket";

function SidebarCollapse(props) {
  const { isAssistantOpen } = useSelector((state) => state.smartAssistant);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showHelpSection, setShowHelpSection] = useState();
  const { _id: manifestId } = props?.userData?.manifest || {};
  const { id, username } = props?.userData?.user || {};
  const showPersonSuite = checkPersonSuite(manifestId);
  const helpSectionRef = useRef();

  const [show, setShow] = useState(false);

  const [showRequestModal, setShowRequestModal] = useState(false);
  const handleShowRequestModal = () => setShowRequestModal(true);
  const handleHideRequestModal = () => setShowRequestModal(false);

  const handleClose = () => setShow(false);

  const getActiveStatusForSubRoutes = (currentRouteName) => {
    const path = location.pathname;
    return path?.includes(currentRouteName) ? "active" : "";
  };

  const getActiveStatus = (link) => (location.pathname.indexOf(link) !== -1 ? "active" : "");

  const getSubMenuActiveClass = (name) => (location.pathname.indexOf(name) !== -1 ? "active" : "");

  // check menu should be shown to the role
  function authenticateSubItems(el) {
    return el.subMenu.filter((e) => Roles?.authenticateTabs(e))?.length > 0;
  }

  function renderMenuItems() {
    let newRoutes = [...routes_hover];
    if (showPersonSuite) {
      newRoutes = routes_hover.map((el) => {
        if (el.name === "drive") {
          return {
            ...el,
            title: "Walk-in",
            icon: <IconRole />,
            link: WALK_IN,
          };
        }
        return el;
      });
    }
    if (carWashConfig()?.isCarWash) {
      newRoutes = routes_hover.map((el) => {
        if (el.name === "drive") {
          return {
            ...el,
            title: carWashConfig()?.label,
          };
        }
        return el;
      });
    }

    newRoutes = newRoutes.map((el) => {
      if (el.name === "wocam") {
        if (isOnlyGroupedRoutesExistsInSubMenu(el)) {
          return {
            ...el,
            link: CAMERA,
            subMenu: [],
          };
        }
      }
      return el;
    });
    return newRoutes.map((el) => {
      if (el?.subMenu?.length && Roles.authenticateTabs(el)) {
        if (authenticateSubItems(el)) {
          return (
            <SubMenu
              className={getSubMenuActiveClass(el.name)}
              title={el.title}
              icon={el.icon}
              key={el.title}
            >
              {el.subMenu.map((item) => {
                if (Roles?.authenticateTabs(item)) {
                  return (
                    <MenuItem
                      key={item.name}
                      onClick={() => {
                        updateManifest();
                        if (location.pathname !== item.link) {
                          history.push(item.link);
                        }
                      }}
                      active={getActiveStatus(item.link)}
                    >
                      <span className="mr-3 sub-icon">{item.icon}</span>
                      {item.name}
                    </MenuItem>
                  );
                }

                return null;
              })}
            </SubMenu>
          );
        }
        return null;
      }
      if (Roles.authenticateTabs(el)) {
        if (el.name === "pos") {
          // console.log(getActiveStatus(el.link), location.pathname, el.link);
        }
        return (
          <MenuItem
            key={el.title}
            onClick={() => {
              updateManifest();
              if (location.pathname !== el.link) {
                history.push(el.link);
              }
            }}
            active={
              hasSubRoutes.includes(el.name)
                ? getActiveStatusForSubRoutes(el.name)
                : getActiveStatus(el.link)
            }
            icon={el.icon}
          >
            {el.title}
          </MenuItem>
        );
      }

      return null;
    });
  }
  const handleCloseAssistant = (isOpen) => {
    dispatch(setIsOpenAssistant(isOpen));
  };

  const renderInSightAIToggle = () => {
    if (getCurrentCompanyWithTheirAIFeatures()?.id && props?.userData?.user?.role !== "Member") {
      return (
        <Menu className="custom-menu" iconShape="square">
          <MenuItem
            onClick={() => {
              handleCloseAssistant(!isAssistantOpen);
            }}
          >
            <button type="button" className="btn insight-ai-toggle">
              <IconSmartAssistant size="18" />
              <div>
                Insight<span>AI</span>
              </div>
            </button>
          </MenuItem>
        </Menu>
      );
    }
    return null;
  };
  return (
    <>
      {/* Help section content */}
      {showHelpSection ? (
        <div ref={helpSectionRef}>
          <HelpSection handleShowRequestModal={handleShowRequestModal} />
        </div>
      ) : null}
      {showRequestModal && (
        <RaiseATicket
          username={username}
          userId={id}
          show={showRequestModal}
          onClose={handleHideRequestModal}
        />
      )}

      <ProSidebar collapsed className="sidebar-block scrollbar scroll" id="style-3">
        <SidebarHeader className="sidebar-global">
          {/**
           *  You can add a header for the sidebar ex: logo
           */}
          <div className="header-block">
            <span>
              {/* Logo */}
              {props?.userData?.manifest?.appLogo ||
              Service.checkHost("airtel.com") ||
              Service.checkHost("openeye") ? (
                <img
                  className="img-fluid mx-auto d-block sidebar-logo"
                  src={
                    props?.userData?.manifest?.appLogo ||
                    // eslint-disable-next-line no-nested-ternary
                    (Service.checkHost("airtel.com")
                      ? "https://wobot-prod-application.s3.amazonaws.com/static/7mN5kVso-ODfA2RT.svg"
                      : Service.checkHost("openeye")
                      ? "https://wobot-prod-application.s3.amazonaws.com/static/uSevKxvtePov-oJq.svg"
                      : "")
                  }
                  alt="logo"
                />
              ) : (
                <img
                  className="img-fluid mx-auto d-block sidebar-logo"
                  src="/assets/images/wobot-Icon-blue.svg"
                  alt="logo"
                />
              )}
            </span>
          </div>
        </SidebarHeader>
        <SidebarContent className="sidebar-global">
          {/**
           *  You can add the content of the sidebar ex: menu, profile details, ...
           */}

          <Menu className="custom-menu" iconShape="square">
            {/* Menu List */}
            {renderMenuItems()}
          </Menu>
          {renderInSightAIToggle()}
        </SidebarContent>
        <SidebarFooter>
          {/**
           *  You can add a footer for the sidebar ex: copyright
           */}

          <Menu>
            <OutsideClickHandler
              onOutsideClick={(event) => {
                if (helpSectionRef.current && !helpSectionRef.current.contains(event.target)) {
                  setShowHelpSection(false);
                }
              }}
            >
              <MenuItem
                className="show-need-help"
                icon={<IconHelp />}
                key="Help"
                onClick={() => {
                  /* window.open("https://help.wobot.ai"); */
                  // Toggler help section
                  setShowHelpSection((prev) => !prev);
                }}
              >
                <span className="mr-3 sub-icon">
                  <IconHelp />
                </span>
                Help
              </MenuItem>
            </OutsideClickHandler>
          </Menu>
        </SidebarFooter>
        <Modal show={show} onHide={handleClose} animation>
          <Modal.Header>
            <Modal.Title>
              <h4 className="modal-title d-block">Reports & Analytics</h4>
            </Modal.Title>
            <button type="button" className="btn btn-single-icon btn-sm" onClick={handleClose}>
              <IconClose />
            </button>
          </Modal.Header>
          <Modal.Body>
            <h5>You’ve found an upcoming feature! </h5>
            <p>
              This section is currently under development and will be released soon. You can then
              find the detailed reports of your tasks, tickets and employees.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-between align-items-end">
            <img src="/assets/images/modal.png" alt="" />
            <Button className="btn btn-primary px-4 btn-md" onClick={handleClose}>
              Keep me updated
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="force-overflow" />
      </ProSidebar>
    </>
  );
}
const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  changeActiveSidebar: (data) => {
    dispatch(changeActiveSidebarAction(data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapse);
